/* Dashboard services */

import { API } from '../../Services/Api';
// import {log} from '@craco/craco/lib/logger'

const request = new API();

export const getMonthlyReport = (params) => {
  return request.api.get(`/reports/monthly-reports/`, params);
}
