const theme = {
  token: {
    colorPrimary: '#E8317B',
    colorLink: '#E8317B',
    colorSuccess: '#1ae784',
    colorWarning: '#ffcc48',
    colorError: '#ff4b40',
    black: '#000000',

    fontFamily: `'Inter', sans-serif`,

    controlHeight: 40,
    controlHeightLG: 50,
    controlHeightSM: 30,

    fontSizeBase: 14,
    fontSize: 14,
    fontSizeLG: 14,
    fontSizeSM: 12,

    fontSizeHeading1: 40,
    fontSizeHeading2: 30,
    fontSizeHeading3: 25,
    fontSizeHeading4: 22,
    fontSizeHeading5: 18,

    colorText: '#667085',
    colorTextBase: '#667085',
    colorTextHeading: '#344054',
    colorTextSecondary: '#000000',
    colorTextDisabled: '#959292',


    radiusBase: 4,
    controlRadius: 4,

    colorBorder: '#D0D5DD',
    colorPrimaryBorder: '#D0D5DD',
    colorPrimaryBorderHover: 'yellow',

    /*card border*/
    colorBorderSecondary: '#EAECF0',

    colorTextPlaceholder: '#BDC4DB',
    colorBgLayout: '#F6F8FC',

    //colorPrimaryActive: 'red',

  },
  components: {
    Button: {
      colorPrimary: '#E8317B',
      colorPrimaryHover: '#D43475',
    },
    Input: {
      colorPrimary: '#808A9D',
      colorBgContainer: '#EAECF0',
    },
    Switch: {
      colorPrimary: '#667085',
      colorPrimaryHover: 'rgba(102, 112, 133, 0.8)',
    },

  },
}
export default theme
