import {createReducer, createActions} from 'reduxsauce'
import Immutable from 'seamless-immutable'
// import dayjs from 'dayjs'

/* ------------- Types and Action Creators ------------- */

const {Types, Creators} = createActions({
  getMonthlyReportRequest: ['payload'],
  getMonthlyReportSuccess: ['payload'],

  monthlyReportFetchingReset: null,
  resetResults: null,
  logoutSuccess: null,
})

export const MonthlyReportTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  monthlyReport: null,
  fetching: false,
  requestResult: null,
})

/* ------------- Functions for reducer cases ------------- */

const getMonthlyReportRequest = (state, {payload}) => {
  return state.merge({fetching: true})
}
const getMonthlyReportSuccess = (state, {payload: data}) => {
  return state.merge({ fetching: false, monthlyReport: data })
}

const resetResults = (state, {}) =>
  state.merge({
    requestResult: null
  })

const monthlyReportFetchingReset = (state) =>
  state.merge({
    fetching: false,
  })

const logoutSuccess = (state) => INITIAL_STATE

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_MONTHLY_REPORT_REQUEST]: getMonthlyReportRequest,
  [Types.GET_MONTHLY_REPORT_SUCCESS]: getMonthlyReportSuccess,

  [Types.RESET_RESULTS]: resetResults,
  [Types.MONTHLY_REPORT_FETCHING_RESET]: monthlyReportFetchingReset,
  [Types.LOGOUT_SUCCESS]: logoutSuccess,
})
