import React, { useState, useEffect, useRef } from 'react';
import cls from 'classname';

import { Form, Input, Button } from 'antd';
//import Icon from '../../Icon';

function LoginForm({ onSubmit, error, ...props }) {

  const [errorText, setErrorText] = useState(null);
  const [isBackError, setIsBackError] = useState(false);

  const [form] = Form.useForm();

  // if is nesessary to hide password from layout html in console
  // const [valuePassword, setValuePassword] = useState('');
  // const inputEl = useRef();

  useEffect(() => {
    if (error) {
      setIsBackError(true);
      if (typeof error === 'string') {
        setErrorText(error);
      } else {
        setErrorText(Object.values(error)[0]);
      }
    }
  }, [error]);

  const onFinish = values => {
    // const newValues = {...values, password: valuePassword};
    onSubmit(values);
  };

  const onFinishFailed = ({ errorFields }) => {
    if (errorFields) setErrorText(errorFields[0]['errors']);
  };

  function handleClearError(e, field) {
    form.setFields([
      {
        name: field,
        errors: [],
      },
    ]);
    setErrorText(null);
    setIsBackError(false);
  }

  // if is nesessary to hide password from layout html in console
  // const handleChangePassword = (value) => {
  //   let length = value.length
  //   let str = new Array(length + 1).join('*');
  //   form.setFieldValue('password', str)
  // }

  // useEffect(() => {
  //   const target = document.querySelector("input.input-pass");
  //   let listenerPaste = function (event) {
  //       event.preventDefault();
  //       let paste = (event.clipboardData || window.clipboardData).getData("text");
  //       setValuePassword(paste);
  //   };

  //   let listenerKeyUp = function (event) {
  //     event.preventDefault();
  //     // console.log(event.key)
  //     if (['Backspace'].includes(event.key)) {
  //       let length = inputEl.current?.input.value.length;
  //       setValuePassword(prev => prev.slice(0, length))
  //       return;
  //     }
  //     if (!['CapsLock', 'Control', 'ArrowLeft', 'Shift', 'ArrowRight', 'ArrowDown', 'ArrowTop' ].includes(event.key)) {
  //       setValuePassword(prev => prev + event.key)
  //       return;
  //     }
  //   };

  //   target.addEventListener("keyup", listenerKeyUp, false);
  //   target.addEventListener("paste", listenerPaste, false);

  //   return () => {
  //     target.removeEventListener("paste", listenerPaste, false);
  //     target.removeEventListener("keyup", listenerKeyUp, false);
  //   }
  // }, []);

  function onBlurHandler(e) {
    const target = e.target;

    setTimeout(() => {
      let error = form.getFieldError(target.name);
      if (error.length) {
        setErrorText(error[0]);
      }
    }, 10);
  }

  return (
    <Form onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          validateTrigger='onSubmit'
          className="login-form"
          layout="vertical"
          requiredMark={false}
          form={form}
    >
      <Form.Item noStyle shouldUpdate={true}>
        {() => <div className={cls('error-text', { 'error-text_empty': !errorText })}>
          {errorText === 'NotAuthorizedException' ? 'Wrong login email or password' : errorText}
        </div>}
      </Form.Item>

      <Form.Item label={<span style={{color: '#667085'}}>Email Address</span>}
                 name="email"
                 validateStatus={errorText || isBackError ? 'error' : null}
                 rules={[
                   { type: 'email', message: 'The email or password you entered is incorrect.' },
                   { required: true, message: 'The email or password you entered is incorrect.' },
                 ]}
      >
        <Input
          size={'large'}
          placeholder={'Email Address'}
          name="email"
          type="email"
          onChange={e => handleClearError(e, 'email')}
          onBlur={onBlurHandler}
        />
      </Form.Item>
      <Form.Item label={<span style={{color: '#667085'}}>Password</span>}
                 name="password"
                 validateStatus={errorText || isBackError ? 'error' : null}
                 rules={[{ required: true, message: 'The email or password you entered is incorrect.' }]}
      >
        <Input.Password
          size={'large'}
          placeholder={'Password'}
          autoComplete="new-password"
          onChange={e => handleClearError(e, 'password')}
          visibilityToggle={false}

          // if is nesessary to hide password from layout html in console
          // ref={inputEl}
          // className='input-pass'
          // onChange={e => {
          //   handleChangePassword(e.target.value);
          //   handleClearError(e, 'password')
          // }}

          /*iconRender={visible => (
            visible
              ? <Icon type='eye' color={'#BDC4DB'}/>
              : <Icon type='eye_invisible' color={'#BDC4DB'}/>
          )
          }*/
        />
      </Form.Item>

      <Button size={'large'} type="primary" htmlType="submit" style={{ width: '100%' , marginTop: 6}}>
        Sign In
      </Button>

      {
        props.children
      }

    </Form>
  );
}

export default LoginForm;
