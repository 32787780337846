import {createReducer, createActions} from 'reduxsauce'
import Immutable from 'seamless-immutable'
// import dayjs from 'dayjs'

/* ------------- Types and Action Creators ------------- */

const {Types, Creators} = createActions({
  getMonthlyScoreReportRequest: ['payload'],
  getMonthlyScoreReportSuccess: ['payload'],

  monthlyScoreReportFetchingReset: null,
  resetResults: null,
  logoutSuccess: null,
})

export const MonthlyScoreReportTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  monthlyScoreReport: null,
  fetching: false,
  requestResult: null,
})

/* ------------- Functions for reducer cases ------------- */

const getMonthlyScoreReportRequest = (state, {payload}) => {
  return state.merge({fetching: true})
}
const getMonthlyScoreReportSuccess = (state, {payload: data}) => {
  return state.merge({ fetching: false, monthlyScoreReport: data })
}

const resetResults = (state, {}) =>
  state.merge({
    requestResult: null
  })

const monthlyScoreReportFetchingReset = (state) =>
  state.merge({
    fetching: false,
  })

const logoutSuccess = (state) => INITIAL_STATE

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_MONTHLY_SCORE_REPORT_REQUEST]: getMonthlyScoreReportRequest,
  [Types.GET_MONTHLY_SCORE_REPORT_SUCCESS]: getMonthlyScoreReportSuccess,

  [Types.RESET_RESULTS]: resetResults,
  [Types.MONTHLY_SCORE_REPORT_FETCHING_RESET]: monthlyScoreReportFetchingReset,
  [Types.LOGOUT_SUCCESS]: logoutSuccess,
})
