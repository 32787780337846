import { create, CancelToken } from 'apisauce';

export class API {
  constructor() {
    this.baseURL = process.env.REACT_APP_BASE_API_ROUTE;

    if (!API.instance) {
      this.createApi();
      this.createInterceptor();

      API.instance = this;
    }

    return API.instance;
  }

  createApi() {
    this.api = create({
      // base URL is read from the 'constructor'
      baseURL: this.baseURL,
      // here are some default headers
      headers: {
        'Content-Type': 'application/json',
      },
      cancelToken: new CancelToken((cancel) => {
        this.source = cancel;
      }),
      // 60 second timeout...
      timeout: 60000,
    });
  }

  cancelAllRequest() {
    API.instance.source();
  }

  createInterceptor() {
    this.interceptors = this.api.axiosInstance.interceptors;
  }
}
