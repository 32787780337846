import {createReducer, createActions} from 'reduxsauce'
import Immutable from 'seamless-immutable'
// import dayjs from 'dayjs'

/* ------------- Types and Action Creators ------------- */

const {Types, Creators} = createActions({
  getReportRequest: ['payload'],
  getReportSuccess: ['payload'],

  getReportWithAlertsRequest: ['payload'],
  getReportWithAlertsSuccess: ['payload'],

  reportFetchingReset: null,
  resetResults: null,
  logoutSuccess: null,
})

export const ReportTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  report: null,
  reportAlerts: null,
  fetching: false,
  result: null,
  // lastRefreshed: null,

})

/* ------------- Functions for reducer cases ------------- */

const getReportRequest = (state, {payload}) => {
  return state.merge({fetching: true})
}
const getReportSuccess = (state, {payload: data}) => {
  return state.merge({ fetching: false, report: data })
}

const getReportWithAlertsRequest = (state, {payload}) => {
  return state.merge({fetching: true})
}
const getReportWithAlertsSuccess = (state, {payload: data}) => {
  return state.merge({ fetching: false, reportAlerts: data })
}


// const resetEventDetailsInfo = (state, {payload: data}) =>
//   state.merge({eventDetailsInfo: null, eventDetailsInfoRaw: null})

const resetResults = (state, {}) =>
  state.merge({

  })

const reportFetchingReset = (state) =>
  state.merge({
    fetching: false,
  })

const logoutSuccess = (state) => INITIAL_STATE

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_REPORT_REQUEST]: getReportRequest,
  [Types.GET_REPORT_SUCCESS]: getReportSuccess,
 
  [Types.GET_REPORT_WITH_ALERTS_REQUEST]: getReportWithAlertsRequest,
  [Types.GET_REPORT_WITH_ALERTS_SUCCESS]: getReportWithAlertsSuccess,

  // [Types.RESET_EVENT_DETAILS_INFO]: resetEventDetailsInfo,

  [Types.RESET_RESULTS]: resetResults,
  [Types.REPORT_FETCHING_RESET]: reportFetchingReset,
  [Types.LOGOUT_SUCCESS]: logoutSuccess,
})
