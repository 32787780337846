import React from 'react'
import {createRoot} from 'react-dom/client'
import {HashRouter} from 'react-router-dom'
import App from './App'
import store from './store'

const container = document.getElementById('root')
const root = createRoot(container) // createRoot(container!) if you use TypeScript

store()
  .then((s) => {
    root.render(
      <HashRouter>
        <App store={s} tab="home"/>
      </HashRouter>
    )
  })
  .catch((err) => {
    console.error(err.message)
  })
