import React from 'react'
import {Button, Typography } from 'antd'

import './styles.less'

const {Title, Text} = Typography

const AcceptTermsModalContent = ({handleDecline, handleAccept}) => {

  return (
    <>
      <div style={{marginBottom: 20}}>
        <Text>
          By using or accessing our Services in any manner, you acknowledge that you accept the practices and policies
          outlined in the
          <a href="https://www.deduce.com/terms-and-conditions/" target="_blank" rel="noopener noreferrer"
             style={{color: "#4A90E2"}}>
            &nbsp;Terms & Conditions&nbsp;
          </a>
          and agree to maintain and comply with the Deduce Services
          <a href="https://www.deduce.com/services-privacy/" target="_blank" rel="noopener noreferrer"
             style={{color: "#4A90E2"}}>
            &nbsp;Privacy Policy.&nbsp;
          </a>
        </Text>
        <Text style={{display: 'block', marginTop: 8}}>
          Carefully review the terms of service for use of the Deduce’s Services.
        </Text>
        <Text style={{display: 'block', marginTop: 8}}>
          This Dashboard is intended to be used by authorized users for the purpose of conducting fraud investigations.
          Any unauthorized access, manipulation, copying, reverse engineering, or use of this Dashboard or the data made
          available therein is strictly prohibited and will be recorded and reported to Deduce and the client as a
          violation of the Master Services Agreement(s) and any applicable regulations.
        </Text>
      </div>
      <div style={{display: 'flex', flexDirection: 'column', rowGap: 12}}>
        <Button
          type="primary"
          style={{width: '100%', height: 50, background: "#0A0D4B"}}
          onClick={handleAccept}
        >
          AGREE & PROCEED
        </Button>
        <Button
          type="primary"
          style={{width: '100%', height: 50, background: "#E8317B"}}
          onClick={handleDecline}
        >
          DECLINE
        </Button>
      </div>
    </>
  )
}

export default AcceptTermsModalContent



