import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { history } from './Utils/utils';
import { reducer as auth } from './Containers/LoginPage/reducer';
import { reducer as startup } from './Redux/StartupRedux';
import { reducer as nav } from './Redux/NavRedux';
import { reducer as errors } from './Redux/ErrorsRedux';

import { reducer as user } from './Containers/User/reducer';
import { reducer as dashboard } from './Containers/DashboardPage/reducer';
import { reducer as report } from './Containers/ReportPage/reducer';
import { reducer as monthlyReport } from './Containers/MonthlyReportPage/reducer';
import { reducer as monthlyScoreReport } from './Containers/MonthlyScoreReportPage/reducer';


export const reducers = combineReducers({
  auth,
  nav,
  errors,
  startup,
  user,
  dashboard,
  report,
  monthlyReport,
  monthlyScoreReport,
  router: connectRouter(history),
});

export default reducers;
