import logo from './logo.svg';
import logoWithText from './logoWithText.svg';

import successful from './logo.svg';
import successfulMobile from './logo.svg';

import checkoutSuccess from './logo.svg';
import checkoutSuccessMobile from './logo.svg';

import gmail from './to-gmail.svg';
import outlook from './toOutlook.svg';
import yahoo from './toYahoo.svg';
import protonmail from './toProtonmail.svg';
import aol from './toAol.svg';
import zoho from './toZoho.svg';
import icloud from './toICloud.svg';
import gmx from './toGMX.svg';
import hey from './toHey.svg';
import yandex from './toYandex.svg';
import google from './google.svg';
import facebook from './facebook.svg';
import apple from './apple.svg';
import email from './email.svg';
import lock from './lock.svg';

import edit from './edit.svg';
import check from './check.svg';

import ResendEmailLoader from './resend-email-loader.svg';

import house from './house.jpeg';
import bg from './bg.jpg';

const Images = {
  logo,
  logoWithText,

  successful,
  successfulMobile,

  checkoutSuccess,
  checkoutSuccessMobile,

  gmail,
  outlook,
  yahoo,
  protonmail,
  aol,
  zoho,
  icloud,
  gmx,
  hey,
  yandex,
  google,
  facebook,
  email,
  lock,
  apple,

  edit,
  check,

  ResendEmailLoader,

  house,
  bg,
};

export default Images;
