import { takeLatest, takeEvery, put, call } from 'redux-saga/effects';

import * as services from './services';
import Creators, { MonthlyReportTypes as constants } from './reducer';

const actions = {
  getMonthlyReportActions: {
    request: Creators.getMonthlyReportRequest,
    success: Creators.getMonthlyReportSuccess,
  },
};

const eventsOptions = {
  [constants.GET_MONTHLY_REPORT_REQUEST]: {
    api: services.getMonthlyReport,
    actions: actions.getMonthlyReportActions,
  },
};

function* apiGenerator(action) {
  const provider = eventsOptions[action.type];
  try {
    const params = action.payload;
    const response = yield call(provider.api, params);

    if (response.ok && response.data) {
      yield put(provider.actions.success(response.data));
    } else {
      yield put(Creators.reportFetchingReset());
      // yield put(ErrorsActions.errorSave(response.data ))
    }
  } catch (errors) {
    yield put(Creators.reportFetchingReset());
  }
}

export default function* apiSaga() {
  yield takeLatest(constants.GET_MONTHLY_REPORT_REQUEST, apiGenerator);
}
