import React, {useEffect, Suspense, lazy} from 'react'
import PropTypes from 'prop-types'
import {Router, Switch, Route} from 'react-router-dom'
import {connect} from 'react-redux'
import {compose} from 'redux'
import {Layout} from 'antd'
//import dayjs from 'dayjs'
import {useResizeDetector} from 'react-resize-detector'
import {
  history,
  //parseJwt
} from '../../Utils/utils'

// COMPONENTS
import StartupPage from '../StartupPage'
import LoginPage from '../LoginPage'
import NotFoundPage from '../NotFoundPage'

import openNotification from '../../Components/Notification'

// REDUCERS
import NavActions from '../../Redux/NavRedux'
import ErrorsActions from '../../Redux/ErrorsRedux'
import AuthActions from '../LoginPage/reducer'
import StartupCreators from '../../Redux/StartupRedux'
import DashboardCreators from '../DashboardPage/reducer'

// HOC
import useWindowSize from '../../hooks/use-window-size'
import withAuth from '../../hoc/withAuth'

const InternalPages = lazy(() => import('./InternalPages'))

function Routes(props) {
  const {
    auth,
    navSetProp,
    userInfo,
    error,
    verifyToken,
    startup,
    dispatch,
  } = props

  const { width, ref } = useResizeDetector();
  const [, innerHeight] = useWindowSize()

  const isAuth = !!auth?.accessToken

  // STARTUP
  useEffect(() => {
    dispatch(StartupCreators.startup())
    const interval = setInterval(() => {
      dispatch(props.getDashboardDateRefresh())
    }, 60000);
    return () => clearInterval(interval)
  }, [])

  useEffect(() => {
    if (innerHeight) document.documentElement.style.setProperty('--app-height', `${innerHeight}px`)
  }, [innerHeight])

  // REFRESH TOKEN
  useEffect(() => {
    if (isAuth) {
      /*let exp = parseJwt(auth.accessToken).exp
      if(exp && exp < dayjs().unix()){
        props.refreshToken({email: userInfo.email, token: auth.refreshToken})
      }*/

      verifyToken(auth.accessToken)
    }
  }, [isAuth, auth?.refreshToken])

  // ERRORS
  useEffect(() => {
    const notification = (message, key) => {
      // console.log('message', message)
      if (message === 'maxAge exceeded') return;
      if (message === 'jwt expired') return;
      if (message === 'name: NotAuthorizedException') return;
      if (message === 'code: NotAuthorizedException') return;
      openNotification({
        type: 'error',
        message: message,
        style: {minWidth: '716px'},
        getContainer: isAuth ? document.getElementById('global-wrap') : document.body,
        duration: 3,
        key: key,
      })
    }

    if (error) {
      if (error.message) {
        if(typeof error.message === 'object'){
          console.log('1error', error)
          for (const i in error.message) {
            notification(`${i}: ${error.message[i]}`, `error_${i}`)
          }
        } else {
          console.log('2error', error)
          notification(error.message, 'error notification')
        }
      } else if (typeof error === 'string') {
        console.log('3error', error)
        notification(error, 'error notification')
      } else {
        for (const i in error) {
          console.log('4error', error)
          notification(`${i}: ${error[i]}`, `error_${i}`)
        }
      }
      props.errorReset()
    }
  }, [!!error])

  // RESIZE
  useEffect(() => {
    const correctWidth = width || window.innerWidth
    if (correctWidth >= 768) {
      navSetProp('isDesktop', true)
      navSetProp('isMobile', false)
    } else {
      navSetProp('isDesktop', false)
      navSetProp('isMobile', true)
    }
  }, [width, !!userInfo])

  if (startup?.success === false && !auth.isValidRefreshToken) {
    return (
      <Layout>
        <Route path="*" component={StartupPage}/>
      </Layout>
    )
  }

  return (
    <Router history={history}>
      <Layout className="main-layout" ref={ref}>
        <Switch>
          <Route exact path="/" component={StartupPage}/>
          <Route path="/login" component={LoginPage}/>

          <Suspense fallback={null}>
            <Route
              path={[
                // "/home",
                "/report",
                "/monthly-report/",
                "/daily-score-report/",
                "/dashboard",
                "/settings",
              ]}
              component={InternalPages}
            />
          </Suspense>
          <Route path="/*" component={NotFoundPage}/>
        </Switch>
      </Layout>
    </Router>
  )
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  userInfo: state.auth.userInfo,
  isAdmin: state.auth.isAdmin,
  startup: state.startup,
  isDesktop: state.nav.isDesktop,
  isMobile: state.nav.isMobile,
  error: state.errors.data,
})

const mapDispatchToProps = (dispatch) => ({
  navSetProp: (key, value) => dispatch(NavActions.navSetProp(key, value)),
  errorReset: () => dispatch(ErrorsActions.errorReset()),
  errorSave: (error) => dispatch(ErrorsActions.errorSave(error)),
  refreshToken: (data) => dispatch(AuthActions.refreshTokenRequest(data)),
  refreshTokenFailure: (data) => dispatch(AuthActions.refreshTokenFailure(data)),
  logout: () => dispatch(AuthActions.logout()),
  verifyToken: (token) => dispatch(AuthActions.verifyTokenRequest({token})),
  getDashboardDateRefresh: (params) => dispatch(DashboardCreators.getDashboardDateRefreshRequest(params)),
  dispatch,
})

Routes.propTypes = {
  auth: PropTypes.shape({
    accessToken: PropTypes.string,
    refreshToken: PropTypes.string,
    isValidRefreshToken: PropTypes.bool,
  }),
  startup: PropTypes.shape({
    success: PropTypes.bool,
  }),
  isAdmin: PropTypes.bool,
  isDesktop: PropTypes.bool,
  isMobile: PropTypes.bool,
  error: PropTypes.any,
  dispatch: PropTypes.func,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(compose(withAuth)(Routes))
